<template>
  <div>
    <v-snackbar v-model="snackbar" :top="true" :color="color" :timeout="6000">
              <span v-if="snackbar_msg!=null">{{ snackbar_msg }}</span>
              <v-btn dark text @click="snackbar = false">Close</v-btn>
    </v-snackbar>
 
      <v-card>
        <v-card-title class="tc-title">
          Placed Student Dashboard
          </v-card-title>
          <v-card-text>
            <v-row>
              <v-col cols="12" md="3" sm="6">
                  <v-select outlined label="Academic Year" multiple autocomplete="off" :items="ay_list" item-text="ay" item-value="id" v-model="selected_ay"></v-select>
              </v-col>
              <v-col cols="12" md="3" sm="6">
                  <v-select outlined label="Organization" multiple  :items="institute_list" item-text="name" item-value="id" v-model="selected_org" ></v-select>
              </v-col>
           
              <v-col cols="12" md="3" sm="6">
                  <v-btn class="mt-2" color="primary darken-1" @click="fetchReport()">Fetch Report</v-btn>
              </v-col>
          </v-row>
          </v-card-text>
      </v-card>
      <br />
      <v-skeleton-loader
      class="ma-3 pa-2"
      v-if="loading"
      v-bind="attrs"
      type="list-item-three-line"
    ></v-skeleton-loader>
      <div v-else class="my-1" v-for="(item,index) in data_list" :key="index">
        <v-card>
        <v-card-title class="tc-title">{{ item.ay }}</v-card-title>
        <v-card-text>
          
            <v-row>
                <v-col cols="12" sm="3" md="3">
                  <div class="cardhover ma-1" @click="org_student_program_wise_count(item.ay_id,item.ay,item1.org_id,item1.org)"  v-for="(item1,index1) in item.data" :key="index1">
                    <v-card  class="tc-title" color="blue" style="height: 110px; ">
                        <v-card-text> <center class="white--text" ><span style="font-size: 20px;"> {{ item1.org }} </span> <br> <span style="font-size: 18px;"> {{item1.total_placed_student  }}/{{  item1.total_applied_student  }}</span></center></v-card-text>
                    </v-card>
                  </div>  
                </v-col>
                <v-col cols="12" sm="4" md="4">
                    <barchart :data="item.chartdata" :key="rerender" :options="chartOptions" />
                </v-col>
              
                <v-col cols="12" sm="4" md="4">
                    <pie :data="item.piedata" :key="rerender" :options="chartOptions" />
                </v-col>
            </v-row>

        </v-card-text>
        </v-card>
      </div>

      <v-dialog max-width="1200px"  v-model="program_wise_student_dialog">
        
        <v-card  class="">
            <v-card-title class="tc-title">{{ dialog_ay}}:{{ dialog_org}} <v-row justify="end"><v-icon @click="program_wise_student_dialog=!program_wise_student_dialog" color="white">mdi mdi-close-circle-outline</v-icon></v-row> </v-card-title>
            <v-card-text>
              
              <v-skeleton-loader
      class="ma-3 pa-2"
      v-if="dialog_loading"
      v-bind="attrs"
      type="list-item-three-line"
    ></v-skeleton-loader>
    <div v-else>
      
          <v-row >
            
            <v-col cols="12" md="6" sm="6">
              <v-row justify="end">
                  <v-btn  @click="exportexcel()" color="success" dark class="mr-3">
                <v-icon dark>mdi-file-excel</v-icon>
                </v-btn>
                </v-row>
                <v-simple-table id="exceltable">
        <thead>
         
         
        </thead>
        <tbody>
        
          <tr>
           
            <th>Program</th>
            <th>Applied Student</th>
            <th>Placed Student</th>
            <th>Un Placed Studnet</th>
          </tr>
          <tr v-for="(item,index1) in program_wise_count_list" :key="index1">
           
          <td> {{ item[0].name }} </td>
          <td class="cardhover" @click="getApplyedstudentByProgramwise(program_wise_apply_count_list[index1][0].id,program_wise_apply_count_list[index1][0].name);" > {{ program_wise_apply_count_list[index1][1]  }}</td>
          <td class="cardhover" @click="getstudent(item[0].id,item[0].name);" > {{ item[1] }}</td>
          <td class="cardhover" @click="getUNPlacedstudentByProgramwise(program_wise_unplaced_count_list[index1][0].id,program_wise_unplaced_count_list[index1][0].name);"> {{ program_wise_unplaced_count_list[index1][1] }}</td>
          </tr>
         
        </tbody>
      </v-simple-table>
              
            </v-col>
         
            <v-col cols="12" md="6" sm="6">
              <barchart :data="program_bar_data" :key="rerender1" :options="chartOptions" />
            </v-col>
          
            <v-col cols="12" md="6" sm="6">
              <fieldset style="border-color: white;" class="pa-3">
                  <legend>Applied</legend>
                <v-row  @click="getApplyedstudentByProgramwise(item[0].id,item[0].name);" class="cardhover" v-for="(item,index1) in program_wise_apply_count_list" :key="index1">
                  <v-col cols="12" sm="11" md="10">
                      {{ item[0].name }}
                    </v-col>
                    <v-col cols="12" sm="1" md="2">
                      {{ item[1] }}
                    </v-col>
                </v-row>
                </fieldset>
              
              </v-col>
          
            <v-col cols="12" md="6" sm="6">
              <fieldset style="border-color: white;" class="pa-3">
                  <legend>Placed</legend>
                <v-row  @click="getstudent(item[0].id,item[0].name);" class="cardhover" v-for="(item,index1) in program_wise_count_list" :key="index1">
                  <v-col cols="12" sm="11" md="10">
                      {{ item[0].name }}
                    </v-col>
                    <v-col cols="12" sm="1" md="2">
                      {{ item[1] }}
                    </v-col>
                </v-row>
                </fieldset>
            
            </v-col>
            <v-col cols="12" md="6" sm="6">
          
              <fieldset style="border-color: white;" class="pa-3">
                  <legend>Un Placed</legend>
                <v-row  @click="getUNPlacedstudentByProgramwise(item[0].id,item[0].name);" class="cardhover" v-for="(item,index1) in program_wise_unplaced_count_list" :key="index1">
                  <v-col cols="12" sm="11" md="10">
                      {{ item[0].name }}
                    </v-col>
                    <v-col cols="12" sm="1" md="2">
                      {{ item[1] }}
                    </v-col>
                </v-row>
                </fieldset>
            </v-col>
          </v-row>


             
            </div>
            </v-card-text>
            </v-card>
      </v-dialog>

     
      <v-dialog max-width="1000px"   v-model="dialog2">
        
        <v-card>
            <v-card-title class="tc-title">{{ dialog_ay}}:{{ dialog_org}}:{{ dialog_program }}<v-row justify="end"><v-icon @click="dialog2=!dialog2" color="white">mdi mdi-close-circle-outline</v-icon></v-row> </v-card-title>
            <v-card-text>
              
              <v-skeleton-loader
      class="ma-3 pa-2"
      v-if="dialog_loading2"
      v-bind="attrs"
      type="list-item-three-line"
    ></v-skeleton-loader>
    <div  v-else>
         <table id="exceltable1" style="display: none;">
        <thead>
          <tr>
            <td></td>
            <td></td>
            <td></td>
          <td><center>{{ dialog_ay}}:{{ dialog_org}}:{{ dialog_program }}</center> </td>
          <td> </td>
          </tr>
        </thead>
        <tbody>
        
          <tr>
            <th>SRNO</th>
            <th>PRN</th>
            <th>NAME</th>
            <th>EMAIL</th>
            <th>MOBILE NO</th>
          </tr>
          <tr v-for="(item,index1) in student_details" :key="index1">
            <td>{{ index1+1 }}</td>
          <td> {{ item.prn }}</td>
          <td> {{ item.name}}</td>
          <td> {{ item.email }}</td>
          <td> {{ item .mobile}}</td>
          </tr>
         
        </tbody>
        </table>
        <v-row justify="end">
                  <v-btn  @click="exportexcel1()" color="success" dark class="ma-3">
                <v-icon dark>mdi-file-excel</v-icon>
                </v-btn>
                </v-row>
              <v-row style="text-align: center;" v-for="(item,index1) in student_details" :key="index1">
                  <v-col cols="12" sm="1" md="1">
                    {{ index1+1}}
                  </v-col>
                  <v-col cols="12" sm="2" md="2">
                    {{ item.prn }}
                  </v-col>
                  <v-col cols="12" sm="3" md="4">
                    {{ item.name }}
                  </v-col>
                  <v-col cols="12" sm="2" md="3">
                    {{ item.email }}
                  </v-col>
                  <v-col cols="12" sm="2" md="2">
                    {{ item.mobile }}
                  </v-col>
              </v-row>
</div>
            </v-card-text>
            </v-card>
      </v-dialog>

     
  </div>
</template>
<script>
import axios from "axios";
import linechart from '../TPO/chart/line_chart.vue'
import barchart from '../TPO/chart/bar_chart.vue'
import polarchart from '../TPO/chart/PolarArea.vue'
import pie from '../TPO/chart/PieChart.vue'
import {table2excel} from "../jquery.table2excel";
export default {
    components: { linechart,barchart,polarchart,pie },
  data: () => ({
    rerender:0,
    rerender1:0,
    search_stud:"",
      loading: false,
      snackbar_msg: "",
      color: "",
      snackbar: false,
      ay_list: [],
      institute_list: [],
      selected_ay: [],
      selected_org: [],
      data_list:[],
     
      chartOptions: {
        scales: {
            yAxes: [{
              ticks: {
                beginAtZero: true
              },
            
            }]
          },
        responsive: true,
      },
      program_wise_student_dialog:false,
      program_wise_count_list:[],
      program_wise_unplaced_count_list:[],
      program_wise_apply_count_list:[],
      dialog_org:"",
      dialog_ay:"",
      dialog_org_id:"",
      dialog_ay_id:"",
      dialog_loading:false,

      dialog2:false,
      student_details:"",
      dialog_program:"",
      dialog_loading2:false,
      program_bar_data:"",
  }),
  mounted() {
      axios
          .post("/TPOReport/getAyAndOgranizationList")
          .then(res => {
              if (res.data.msg == "200") {
                    this.ay_list = res.data.ay_list;
                    this.selected_ay .push(res.data.ay) ;
                    this.institute_list = res.data.institute_list;
                    if(this.institute_list)
                    this.selected_org.push(this.institute_list[0].id);
              } else {
                this.showSnackbar("red", res.data.msg);
              }
          });
  },
  methods: {
    exportexcel() {
           $("#exceltable").table2excel({
                name: "PLACEMENT_COUNT_PROGRAM_WISE",
                filename: "PLACEMENT_COUNT_PROGRAM_WISE",
                fileext: ".xls" 
           }); 
       },
       exportexcel1() {
           $("#exceltable1").table2excel({
                name: "STUDENT_DETAILS",
                filename: "STUDENT_DETAILS",
                fileext: ".xls" 
           }); 
       },
       getUNPlacedstudentByProgramwise(program_id,program_name)
    {
      this.dialog_program=program_name;
      this.dialog2=true;
      this.dialog_loading2=true;
      const data = {
                selected_org: this.dialog_org_id,
                selected_ay:this.dialog_ay_id,
                selected_program:program_id
          };
          axios
          .post("/TPOReport/getUNPlacedstudentByProgramwise",data)
          .then(res => {
              if (res.data.msg == "200") {
                this.student_details=res.data.details;
                this.dialog_loading2=false;
              } else {
                this.dialog2=false;
                this.dialog_loading2=false;
                this.showSnackbar("red", res.data.msg);
            return;
              }
          });

    },
    getApplyedstudentByProgramwise(program_id,program_name)
    {
      this.dialog_program=program_name;
      this.dialog2=true;
      this.dialog_loading2=true;
      const data = {
                selected_org: this.dialog_org_id,
                selected_ay:this.dialog_ay_id,
                selected_program:program_id
          };
          axios
          .post("/TPOReport/getApplyedstudentByProgramwise",data)
          .then(res => {
              if (res.data.msg == "200") {
                this.student_details=res.data.details;
                this.dialog_loading2=false;
              } else {
                this.dialog2=false;
                this.dialog_loading2=false;
                this.showSnackbar("red", res.data.msg);
            return;
              }
          });

    },

    getstudent(program_id,program_name)
    {
      this.dialog_program=program_name;
      this.dialog2=true;
      this.dialog_loading2=true;
      const data = {
                selected_org: this.dialog_org_id,
                selected_ay:this.dialog_ay_id,
                selected_program:program_id
          };
          axios
          .post("/TPOReport/getPlacedstudentByProgramwise",data)
          .then(res => {
              if (res.data.msg == "200") {
                this.student_details=res.data.details;
                this.dialog_loading2=false;
              } else {
                this.dialog2=false;
                this.dialog_loading2=false;
                this.showSnackbar("red", res.data.msg);
            return;
              }
          });

    },
    org_student_program_wise_count(ay_id,ay,org_id,org)
    {
      this.dialog_org=org;
                this.dialog_ay=ay;
                this.dialog_org_id=org_id;
                this.dialog_ay_id=ay_id;
      this.program_wise_student_dialog=true;
      this.program_wise_count_list=[];
      this.program_wise_apply_count_list=[],
      this.program_wise_unplaced_count_list=[],
      this.dialog_loading=true;
      this.program_bar_data="";
      const data = {
                selected_org: org_id,
                selected_ay:ay_id,
          };
      axios
          .post("/TPOReport/org_student_program_wise_count",data)
          .then(res => {
              if (res.data.msg == "200") {
                console.log(res.data);
                this.rerender1++;
                this.program_wise_count_list=res.data.program_wise_count_list;
                this.program_wise_unplaced_count_list=res.data.program_wise_unplaced_count_list;
                this.program_wise_apply_count_list=res.data.program_wise_apply_count_list;
                if(this.program_wise_apply_count_list!='undefined' || this.program_wise_apply_count_list.length>0)
                {
                  for(var i in this.program_wise_apply_count_list)
                  {
                    var flag=false;
                    var flag1=false;
                      for(var j in this.program_wise_count_list)
                      {
                        if(this.program_wise_apply_count_list[i][0].id==this.program_wise_count_list[j][0].id)
                        {
                           flag=true;
                        }
                      }
                      if(flag==false)
                      {
                        var map={"abbrivation":this.program_wise_apply_count_list[i][0].abbrivation,"name":this.program_wise_apply_count_list[i][0].name,"id":this.program_wise_apply_count_list[i][0].id};
                        var array=[]
                        array.push(map);
                        array.push(0);
                        this.program_wise_count_list.push(array);
                      }

                      for(var j in this.program_wise_unplaced_count_list)
                      {
                        if(this.program_wise_apply_count_list[i][0].id==this.program_wise_unplaced_count_list[j][0].id)
                        {
                           flag1=true;
                        }
                      }
                      if(flag1==false)
                      {
                        var map={"abbrivation":this.program_wise_apply_count_list[i][0].abbrivation,"name":this.program_wise_apply_count_list[i][0].name,"id":this.program_wise_apply_count_list[i][0].id};
                        var array=[]
                        array.push(map);
                        array.push(0);
                        this.program_wise_unplaced_count_list.push(array);
                      }

                  }
                }
                console.log(this.program_wise_apply_count_list)
                console.log(this.program_wise_count_list)
                console.log(this.program_wise_unplaced_count_list)

                
                if((this.program_wise_count_list!='undefined' || this.program_wise_count_list.length>0)&&(this.program_wise_unplaced_count_list!='undefined' || this.program_wise_unplaced_count_list.length>0)&&(this.program_wise_apply_count_list!='undefined' || this.program_wise_apply_count_list.length>0)){
                  var label=[]; 
                  var placed_data=[];
                  var unplaced_data=[];
                  var apply_data=[];
                  var total=0
                  var total_unplaced=0
                  var total_apply=0
                  for(var x in this.program_wise_count_list)
                    {
                      for(var y in this.program_wise_unplaced_count_list)
                      {
                        for(var z in this.program_wise_apply_count_list)
                        {
                          if(this.program_wise_count_list[x][0].id==this.program_wise_unplaced_count_list[y][0].id  &&  this.program_wise_count_list[x][0].id==this.program_wise_apply_count_list[z][0].id)
                          {
                              label.push(this.program_wise_count_list[x][0].abbrivation);
                              placed_data.push(this.program_wise_count_list[x][1]);
                              unplaced_data.push(this.program_wise_unplaced_count_list[y][1]);
                              apply_data.push(this.program_wise_apply_count_list[z][1]);
                          }
                         
                          if(x==0 && y==0)
                          total_apply+=this.program_wise_apply_count_list[z][1];
                        }
                        if(x==0)
                          total_unplaced+=this.program_wise_unplaced_count_list[y][1];
                      }
                      total+=this.program_wise_count_list[x][1];
                    }
                    var map={"name":"TOTAL","id":"TOTAL"};
                    var array=[]
                    array.push(map);
                    array.push(total);
                    this.program_wise_count_list.push(array);

                  
                    array=[]
                    array.push(map);
                    array.push(total_unplaced);
                    this.program_wise_unplaced_count_list.push(array);

                    array=[]
                    array.push(map);
                    array.push(total_apply);
                    this.program_wise_apply_count_list.push(array);
                    this.program_bar_data={"labels":label,"datasets":[{"data":apply_data,"label":"Applied","backgroundColor": 'skyblue',},{"data":unplaced_data,"label":"UnPlaced","backgroundColor": 'red',},{"data":placed_data,"label":"Placed","backgroundColor": 'green',}]};
                }
                  this.dialog_loading=false;
                  
              } else {
                  console.log(res.data.msg);
                  this.program_wise_student_dialog=flase;
                  this.dialog_loading=false;
                  this.showSnackbar("red", res.data.msg);
              }
          });
    },
      
      fetchReport() { 
        if(this.selected_org=='undefined' || this.selected_org.length==0 )
        {
            this.showSnackbar("red", "PLease Select At Least One Ogranization");
            return;
        }
        if(this.selected_ay=='undefined' || this.selected_ay.length==0 )
        {
            this.showSnackbar("red", "PLease Select At Least One Academic Year");
            return;
        }
        this.data_list=[];
        this.rerender=0;
          this.loading = true;
          const data = {
                selected_org: this.selected_org,
                selected_ay:this.selected_ay,
          };
          axios
              .post("TPOReport/getPlacedStudentDetails",data)
              .then((res) => {
                  if (res.data.msg == "200") {   
                        this.loading = false;                    
                        this.data_list=res.data.final_list;
                        this.rerender++;
                  } else {
                        this.loading = false;
                        this.showSnackbar("red", res.data.msg);
                  }
              })
      },
      showSnackbar(clr, msg) {
          this.snackbar = true;
          this.color = clr;
          this.snackbar_msg = msg;
      },
  }
};
</script>
<style scoped>
  .required{
      color:red;
      font-size:14px;
      font-style:bold;
  }

  .tc-title {
      background-image: linear-gradient(-90deg, skyblue, #057996);
      color: #fff;
      border-radius: 3px;
  }

  .pointer{
   cursor: pointer;
}
.cardhover:hover{
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  cursor: pointer;
}
legend {
  background-color: gray;
  color: white;
  padding: 5px 10px;
}
</style>    